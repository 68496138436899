import type {
    FundraisingCampaign,
    DashboardFundraisingCampaign,
    DashboardFundraisingCampaignData,
    DashboardFundraisingCampaignDonation,
    DashboardFundraisingCampaignTimelineUpdate,
    DashboardFundraisingCampaignTimelineUpdateData,
    FundraisingCampaignCategory,
    FundraisingCampaignQuota,
    FundraisingCampaignType,
    SavedFundraisingCampaign,
    SavedFundraisingCampaignsData,
    DashboardUserFundraisingCampaignDonationsData,
    DashboardUserFundraisingCampaignDonation
} from "~/types";

export const useFundraisingDataService = () => {
    const nuxtApp = useNuxtApp();
    const route = useRoute();

    /* Resources */
    const fetchFundraisingCampaignCategories = async () => {
        const {data, error} = await useAsyncData('fundraising-campaign-categories',
            () => nuxtApp.$getFundraisingCampaignCategories()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as FundraisingCampaignCategory[];
        }
    };

    const fetchFundraisingCampaignTypes = async () => {
        const {data, error} = await useAsyncData('fundraising-campaign-types',
            () => nuxtApp.$getFundraisingCampaignTypes()
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const campaignTypes = (data.value.data as string[]).map((element) => {
                return {
                    title: element
                };
            });
            return campaignTypes as FundraisingCampaignType[];
        }
    };

    const fetchFundraisingCampaignQuota = async () => {
        const {data, error} = await useAsyncData('fundraising-campaign-quota',
            () => nuxtApp.$getFundraisingCampaignQuota()
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as FundraisingCampaignQuota
        }
    };
    /* Resources End */

    /* Fundraising Dashboard Data */
    const fetchFundraisingCampaignsDataForDashboardByFilter = async () => {
        const {data, error, refresh} = await useAsyncData(`${route.query.filter ?? 'active'}-fundraising-campaigns`,
            () => useNuxtApp().$getMyFundraisingCampaignsByFilter((route.query.filter as string) ?? 'active')
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            const filteredFundraisingCampaignsData = computed<DashboardFundraisingCampaignData>(() => {
                return {
                    fundraising_campaigns: data.value.data.data,
                    pagination_meta_data: data.value.data.meta,
                    pagination_links: data.value.data.links,
                    statistics: data.value.statistics,
                }
            });

            return ref({filteredFundraisingCampaignsData, refresh});
        }
    };

    const fetchFundraisingCampaignsDataForDashboardFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getMyFundraisingCampaignsByFilterFromUrl(url);
            const filteredFundraisingCampaignsData = {
                fundraising_campaigns: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
                statistics: response.statistics,
            } as DashboardFundraisingCampaignData;

            return filteredFundraisingCampaignsData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };
    /* Fundraising Dashboard Data Ends */
    /* Dashboard Fundraising Campaign */
    const fetchFundraisingCampaignResourceForDashboard = async (slug: string) => {
        const {data, error} = await useAsyncData('dashboard-fundraising-campaign',
            () => nuxtApp.$getMyFundraisingCampaignBySlug(slug)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as DashboardFundraisingCampaign
        }
    };

    const requestToCreateNewFundraisingCampaign = async (data: object) => {
        try {
            const response = await nuxtApp.$createFundraisingCampaign(data);
            return response.data as DashboardFundraisingCampaign;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToUpdateFundraisingCampaign = async (data: object) => {
        try {
            const response = await nuxtApp.$updateFundraisingCampaign(route.params.slug as string, data);
            return response.data as DashboardFundraisingCampaign;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToSubmitCurrentDashboardFundraisingCampaignForApproval = async () => {
        try {
            const response = await useNuxtApp().$submitMyFundraisingCampaignForApprovalBySlug(route.params.slug as string)
            return response.data;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToMarkCurrentDashboardFundraisingCampaignAsCompleted = async () => {
        try {
            const response = await nuxtApp.$completeMyFundraisingCampaignBySlug(route.params.slug as string);
            return response.data;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    /* Dashboard Fundraising Campaign Ends */


    /*Dashboard Saved Fundraising Campaigns*/
    const fetchSavedFundraisingCampaignsDataForDashboard = async () => {
        const {data, error, refresh} = await useAsyncData(`saved-fundraising-campaigns`,
            () => useNuxtApp().$getMySavedFundraisingCampaigns()
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed<SavedFundraisingCampaignsData>(() => {
                return {
                    fundraising_campaigns: data.value.data as SavedFundraisingCampaign[],
                    pagination_meta_data: data.value.meta as PaginationMeta,
                    pagination_links: data.value.links as PaginationLinks,
                }
            });
        }
    }

    const fetchSavedFundraisingCampaignsDataForDashboardFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getFundraisingCampaignsDataFromUrl(url);
            return {
                fundraising_campaigns: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
            } as SavedFundraisingCampaignsData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };

    const requestToUnSaveFundraisingCampaign = async (slug: string) => {
        try {
            const response = await nuxtApp.$unSaveFundraisingCampaignBySlug(slug);
            return response.data as FundraisingCampaign;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    /*Dashboard Saved Classified Ads End*/

    /*Dashboard User Fundraising Campaign Donation */
    const fetchDashboardUserFundraisingCampaignDonationsDataForDashboard = async () => {
        const {data, error, refresh} = await useAsyncData(`dashboard-fundraising-campaigns-donations`,
            () => useNuxtApp().$getMyFundraisingCampaignGivenDonations()
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed<DashboardUserFundraisingCampaignDonationsData>(() => {
                return {
                    fundraising_campaign_donations: data.value.data as DashboardUserFundraisingCampaignDonation[],
                    pagination_meta_data: data.value.meta as PaginationMeta,
                    pagination_links: data.value.links as PaginationLinks,
                }
            });
        }
    }

    const fetchDashboardUserFundraisingCampaignDonationsDataForDashboardFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getFundraisingCampaignsDataFromUrl(url);
            return {
                fundraising_campaign_donations: response.data.data,
                pagination_meta_data: response.data.meta,
                pagination_links: response.data.links,
            } as DashboardUserFundraisingCampaignDonationsData;

        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };
    /*Dashboard User Fundraising Campaign Donation End */

    /*Dashboard Fundraising Campaign Images*/


    const fetchCurrentDashboardFundraisingCampaignImages = async () => {
        const {data, error} = await useAsyncData('dashboard-fundraising-campaign-images',
            () => nuxtApp.$getMyFundraisingCampaignImagesByFundraisingCampaignSlug(route.params.slug as string)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value?.data as string[];
        }
    };

    const requestToDeleteFundraisingCampaignImageAtIndex = async (index: number) => {
        try {
            const response = await nuxtApp.$deleteMyFundraisingCampaignImageByFundraisingCampaignImageIndex(
                route.params.slug as string,
                index
            );
            return response.data.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    };

    /*Dashboard Fundraising Campaign Images Ends*/

    /* Timeline Update */

    const fetchFundraisingCampaignsTimelineUpdatesDataForDashboard = async () => {
        const {data, error, refresh} = await useAsyncData(`dashboard-fundraising-campaign-timeline-updates`,
            () => useNuxtApp().$getMyFundraisingCampaignTimelineUpdatesDataBySlug(route.params.slug as string)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
        } else {
            return {data, refresh};
        }
    };

    const fetchFundraisingCampaignTimelineUpdatesDataForDashboardFromUrl = async (url: string) => {
        const response = await useNuxtApp().$getFundraisingCampaignTimelineUpdatesDataFromUrl(url);

        if (response.error != null) {
            setError(response.error as ApiError);
            return null;
        } else {
            return {
                timeline_updates: response.data as DashboardFundraisingCampaignTimelineUpdate[],
                pagination_meta_data: response.meta as PaginationMeta,
                pagination_links: response.links as PaginationLinks,
            } as DashboardFundraisingCampaignTimelineUpdateData;
        }
    };


    const fetchFundraisingCampaignTimelineUpdatesDataForDashboardFromPageNumber = async (pageNumber: number) => {
        try {
            const response = await useNuxtApp().$getFundraisingCampaignTimelineUpdatesDataBySlugFromPageNumber(route.params.slug as string, pageNumber)
            return {
                timeline_updates: response.data as DashboardFundraisingCampaignTimelineUpdate[],
                pagination_meta_data: response.meta as PaginationMeta,
                pagination_links: response.links as PaginationLinks,
            } as DashboardFundraisingCampaignTimelineUpdateData;
        } catch (error: any) {
            setError(error.data as ApiError);
        }
    };

    const submitFormToCreateNewFundraisingCampaignTimelineUpdate = async (newTimelineUpdateForm: object) => {
        try {
            const response = await nuxtApp.$createFundraisingCampaignTimelineUpdate(route.params.slug as string, newTimelineUpdateForm)
            return response.data as DashboardFundraisingCampaignTimelineUpdate;
        } catch (error: any) {
            setError(error.data as ApiError)
            return null;
        }
    }

    const submitFormToUpdateFundraisingCampaignTimelineUpdate = async (timelineUpdateId: string, timelineUpdateForm: object) => {
        try {
            const response = await nuxtApp.$updateFundraisingCampaignTimelineUpdate(route.params.slug as string, timelineUpdateId, timelineUpdateForm);
            return response.data as DashboardFundraisingCampaignTimelineUpdate
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToDeleteFundraisingCampaignTimelineUpdate = async (timelineUpdateId: string) => {
        try {
            const response = await nuxtApp.$deleteFundraisingCampaignTimelineUpdate(route.params.slug as string, timelineUpdateId);
            return response.data;
        } catch (error: any) {
            return null;
        }
    }
    /* Timeline Update Ends */

    /* Donations */

    const fetchFundraisingCampaignsDonationsDataForDashboard = async () => {
        const {data, error, refresh} = await useAsyncData(`dashboard-fundraising-campaign-donations`,
            () => useNuxtApp().$getMyFundraisingCampaignDonationsDataBySlug(route.params.slug as string)
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return {data, refresh};
        }
    };

    const fetchFundraisingCampaignDonationsDataForDashboardFromUrl = async (url: string) => {
        const response = await useNuxtApp().$getFundraisingCampaignDonationsDataFromUrl(url);

        if (response.error != null) {
            setError(response.error as ApiError);
            return null;
        } else {
            return {
                donations: response.data as DashboardFundraisingCampaignDonation[],
                pagination_meta_data: response.meta as PaginationMeta,
                pagination_links: response.links as PaginationLinks,
            };
        }
    };

    /* Donations Ends */

    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

    return {
        fetchFundraisingCampaignCategories,
        fetchFundraisingCampaignTypes,
        fetchFundraisingCampaignQuota,

        requestToCreateNewFundraisingCampaign,
        requestToUpdateFundraisingCampaign,
        requestToSubmitCurrentDashboardFundraisingCampaignForApproval,
        requestToMarkCurrentDashboardFundraisingCampaignAsCompleted,
        fetchFundraisingCampaignResourceForDashboard,

        fetchFundraisingCampaignsDataForDashboardByFilter,
        fetchFundraisingCampaignsDataForDashboardFromUrl,

        fetchFundraisingCampaignTimelineUpdatesDataForDashboardFromUrl,
        fetchFundraisingCampaignTimelineUpdatesDataForDashboardFromPageNumber,
        fetchFundraisingCampaignsTimelineUpdatesDataForDashboard,
        submitFormToCreateNewFundraisingCampaignTimelineUpdate,
        submitFormToUpdateFundraisingCampaignTimelineUpdate,
        requestToDeleteFundraisingCampaignTimelineUpdate,

        fetchCurrentDashboardFundraisingCampaignImages,
        requestToDeleteFundraisingCampaignImageAtIndex,

        fetchSavedFundraisingCampaignsDataForDashboard,
        fetchSavedFundraisingCampaignsDataForDashboardFromUrl,
        requestToUnSaveFundraisingCampaign,

        fetchDashboardUserFundraisingCampaignDonationsDataForDashboard,
        fetchDashboardUserFundraisingCampaignDonationsDataForDashboardFromUrl,


        fetchFundraisingCampaignsDonationsDataForDashboard,
        fetchFundraisingCampaignDonationsDataForDashboardFromUrl,

    }

}