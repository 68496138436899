import type { DashboardFundraisingCampaign, DashboardFundraisingCampaignData, DashboardFundraisingCampaignDonationData, FundraisingCampaignCategory, FundraisingCampaignQuota, FundraisingCampaignType } from "~/types";

export const useFundraisingDataManager = () => {
    const route = useRoute();
    const fundraisingStore = useFundraising();
    const fundraisingDataService = useFundraisingDataService();

    /* Resources */
    const getFundraisingCampaignCategories = async () => {
        const storedFundraisingCampaignCategories = computed<FundraisingCampaignCategory[]>(() => {
            return fundraisingStore.fundraising_campaign_categories;
        })

        if (storedFundraisingCampaignCategories.value == null || storedFundraisingCampaignCategories.value.length == 0 || !fundraisingStore.isFundraisingCampaignCategoriesValid()) {
            const fundraisingCampaignCategories = await fundraisingDataService.fetchFundraisingCampaignCategories();
            if(fundraisingCampaignCategories)
            fundraisingStore.setFundraisingCampaignCategories(fundraisingCampaignCategories);
        }

        return storedFundraisingCampaignCategories;
    }

    const getFundraisingCampaignTypes = async () => {
        const storedFundraisingCampaignTypes = computed<FundraisingCampaignType[]>(() => {
            return fundraisingStore.fundraising_campaign_types;
        })

        if (storedFundraisingCampaignTypes.value == null || storedFundraisingCampaignTypes.value.length == 0 || !fundraisingStore.isFundraisingCampaignTypesValid()) {
            const fundraisingCampaignTypes = await fundraisingDataService.fetchFundraisingCampaignTypes();
            if(fundraisingCampaignTypes)
                fundraisingStore.setFundraisingCampaignTypes(fundraisingCampaignTypes);
        }

        return storedFundraisingCampaignTypes;
    }

    const getFundraisingCampaignQuota = async () => {
        const storedFundraisingCampaignQuota = computed<FundraisingCampaignQuota | null>(() => {
            return fundraisingStore.fundraising_campaign_quota;
        })
        if (storedFundraisingCampaignQuota.value == null || !fundraisingStore.isFundraisingCampaignQuotaValid()) {
            const fundraisingCampaignQuota = await fundraisingDataService.fetchFundraisingCampaignQuota();
            if(fundraisingCampaignQuota)
            fundraisingStore.setFundraisingCampaignQuota(fundraisingCampaignQuota);
        }

        return storedFundraisingCampaignQuota;
    }
    /* Resources End */
    /* Fundraising Dashboard Data */
    const getDashboardFundraisingCampaignsByFilter = async () => {
        const dashboardData = await fundraisingDataService.fetchFundraisingCampaignsDataForDashboardByFilter();
        return computed(() => dashboardData?.value)
    }

    const getDashboardFundraisingCampaignsFromUrl = async (url: string) => {
        const results = await fundraisingDataService.fetchFundraisingCampaignsDataForDashboardFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }

    /* Fundraising Dashboard Data Ends */


    /* Dashboard Fundraising Campaign */

    /* Dashboard Fundraising Campaign Ends */
    const getCurrentDashboardFundraisingCampaign = async () => {
        const storedFundraisingCampaign = computed(() => {
            return fundraisingStore.current_dashboard_fundraising_campaign;
        })

        const slug = route.params.slug as string;
        if ((storedFundraisingCampaign.value == null || storedFundraisingCampaign.value.id == null || storedFundraisingCampaign.value.slug != route.params.slug || !fundraisingStore.isCurrentDashboardFundraisingCampaignValid()) && slug) {
            const fundraisingCampaign = await fundraisingDataService.fetchFundraisingCampaignResourceForDashboard(slug);
            if(fundraisingCampaign)
                fundraisingStore.setCurrentDashboardFundraisingCampaign(fundraisingCampaign);
        }

        return storedFundraisingCampaign;
    }

    const createNewFundraisingCampaign = async (data: object) => {
        const createdFundraisingCampaign = await fundraisingDataService.requestToCreateNewFundraisingCampaign(data);
        if (createdFundraisingCampaign) {
            fundraisingStore.setCurrentDashboardFundraisingCampaign(createdFundraisingCampaign);
            useAlertSetter().setSuccessAlert("Fundraiser Created Successfully");
            navigateTo({
                name: "dashboard-fundraising",
                query: {
                    filter: "active",
                }
            });
        }
    };

    const updateFundraisingCampaign = async (data: object) => {
        const updatedFundraisingCampaign = await fundraisingDataService.requestToUpdateFundraisingCampaign(data);
        if (updatedFundraisingCampaign) {
            fundraisingStore.setCurrentDashboardFundraisingCampaign(updatedFundraisingCampaign);
            useAlertSetter().setSuccessAlert("Fundraiser Updated Successfully");
            navigateTo({
                name: "dashboard-fundraising-slug",
                params: { slug: fundraisingStore.current_dashboard_fundraising_campaign?.slug as string },
            });
        }
    };

    const completeCurrentDashboardFundraisingCampaign = async () => {
        const completedFundraisingCampaign = await fundraisingDataService.requestToMarkCurrentDashboardFundraisingCampaignAsCompleted();
        if (completedFundraisingCampaign) {
            useFundraising().setCurrentDashboardFundraisingCampaign(completedFundraisingCampaign)
            navigateTo({
                name: "dashboard-fundraising-slug",
                params: {
                    slug: completedFundraisingCampaign.slug
                }
            });
        }
    };

    const submitCurrentDashboardFundraisingCampaignForApproval = async () => {
        const submittedDashboardFundraisingCampaign = await fundraisingDataService.requestToSubmitCurrentDashboardFundraisingCampaignForApproval();
        if (submittedDashboardFundraisingCampaign) {
            fundraisingStore.setCurrentDashboardFundraisingCampaign(submittedDashboardFundraisingCampaign);
            navigateTo({
                name: "dashboard-fundraising-slug",
                params: {
                    slug: submittedDashboardFundraisingCampaign.slug,
                },
            });
        }
    };

    /* Fundraising Campaign Images */

    const getCurrentDashboardFundraisingCampaignImages = async () => {
        const storedFundraisingCampaignImages = computed(() => {
            return fundraisingStore.current_dashboard_fundraising_campaign_images;
        })
        if (storedFundraisingCampaignImages.value == null || storedFundraisingCampaignImages.value.length == 0 || !fundraisingStore.isCurrentDashboardFundraisingCampaignImagesValid()) {
            const images =  await fundraisingDataService.fetchCurrentDashboardFundraisingCampaignImages();
            if(images)
            fundraisingStore.setCurrentDashboardFundraisingCampaignImages(images);
        }
        return storedFundraisingCampaignImages;
    }

    const addImageToCurrentDashboardFundraisingCampaignImages = (imageUrl: string) => {
        const storedFundraisingCampaignImages = computed(() => {
            return fundraisingStore.current_dashboard_fundraising_campaign_images;
        })
        fundraisingStore.addImageToCurrentDashboardFundraisingCampaignImages(imageUrl);

        useAlertSetter().setSuccessAlert("Successfully added image for fundraiser");
        return storedFundraisingCampaignImages;
    }

    const removeImageFromCurrentDashboardFundraisingCampaignImagesAtIndex = async (index: number) => {
        const storedFundraisingCampaignImages = computed(() => {
            return fundraisingStore.current_dashboard_fundraising_campaign_images;
        })
        const result = await fundraisingDataService.requestToDeleteFundraisingCampaignImageAtIndex(index);
        if(result)
        fundraisingStore.removeImageFromCurrentDashboardFundraisingCampaignImagesAtIndex(index);
        
        useAlertSetter().setSuccessAlert("Successfully removed image from fundraiser");
        return storedFundraisingCampaignImages;
    }

    /* Fundraising Campaign Images End */


    /* Timeline Update */

    const getDashboardFundraisingCampaignTimelineUpdates = async () => {
        const results = await fundraisingDataService.fetchFundraisingCampaignsTimelineUpdatesDataForDashboard();
        if (results?.data && results?.refresh != null) {
            const fundraisingCampaignTimelineUpdatesData = {
                timeline_updates: results.data.value.data,
                pagination_meta_data: results.data.value.meta,
                pagination_links: results.data.value.links,
            }
            return { fundraisingCampaignTimelineUpdatesData, refresh: results.refresh };
        }
    }

    const getDashboardFundraisingCampaignTimelineUpdatesDataFromUrl = async (url: string) => {
        const timelineUpdatesData = await fundraisingDataService.fetchFundraisingCampaignTimelineUpdatesDataForDashboardFromUrl(url);
        if (timelineUpdatesData) {
            useScrollManager().scrollToTop();
            return computed(() => timelineUpdatesData);
        }
    }

    const getDashboardFundraisingCampaignTimelineUpdatesDataByPageNumber = async (pageNumber: number) => {
        const timelineUpdatesOfPageNumber = await fundraisingDataService.fetchFundraisingCampaignTimelineUpdatesDataForDashboardFromPageNumber(pageNumber);
        if (timelineUpdatesOfPageNumber) {
            useScrollManager().scrollToTop();
            return computed(() => timelineUpdatesOfPageNumber);
        }
    }

    const createNewFundraisingCampaignTimelineUpdate = async (newTimelineUpdateForm: object) => {
        const fundraisingCampaignTimelineUpdate = await fundraisingDataService.submitFormToCreateNewFundraisingCampaignTimelineUpdate(newTimelineUpdateForm);
        if (fundraisingCampaignTimelineUpdate) {
            useAlertSetter().setSuccessAlert("Successfully created update for fundraiser");
            fundraisingStore.increaseTimelineUpdateCountForCurrentDashboardFundraisingCampaign();
            return fundraisingCampaignTimelineUpdate;
        }
        return null;
    }

    const updateFundraisingCampaignTimelineUpdate = async (timelineUpdateId: string, timelineUpdateForm: object) => {
        const updatedFundraisingCampaignTimelineUpdate = await fundraisingDataService.submitFormToUpdateFundraisingCampaignTimelineUpdate(timelineUpdateId, timelineUpdateForm);
        if (updatedFundraisingCampaignTimelineUpdate != null) {
            useAlertSetter().setSuccessAlert("Successfully updated data of an update for fundraiser");
            await getDashboardFundraisingCampaignTimelineUpdates();
            return updatedFundraisingCampaignTimelineUpdate;
        }
        return null;
    }

    const deleteFundraisingCampaignTimelineUpdate = async (timelineUpdateId: string) => {
        const result = await fundraisingDataService.requestToDeleteFundraisingCampaignTimelineUpdate(timelineUpdateId);
        if (result) {
            fundraisingStore.decreaseTimelineUpdateCountForCurrentDashboardFundraisingCampaign();
            useAlertSetter().setSuccessAlert("Successfully deleted an update for fundraiser");
        }
    }

    /* Timeline Update Ends */


    /* Donation */

    const getDashboardFundraisingCampaignDonationsDataFromUrl = async (url: string) => {
        const donationsData = await fundraisingDataService.fetchFundraisingCampaignDonationsDataForDashboardFromUrl(url);
        if (donationsData) {
            useScrollManager().scrollToTop();
            return computed(() => donationsData);
        }
    }

    const getDashboardFundraisingCampaignDonations = async () => {
        const results = await fundraisingDataService.fetchFundraisingCampaignsDonationsDataForDashboard();
        if (results?.data && results.refresh != null) {
            const fundraisingCampaignDonationsData = {
                donations: results.data.value.data,
                pagination_meta_data: results.data.value.meta,
                pagination_links: results.data.value.links,
            };
            return { fundraisingCampaignDonationsData, refresh: results.refresh };
        }
    }
    /* Donation Ends*/

    /*Save Fundraising Campaign*/
    const getDashboardSavedFundraisingCampaigns = async () => {
        const dashboardData = await fundraisingDataService.fetchSavedFundraisingCampaignsDataForDashboard();
        return computed(() => dashboardData?.value)
    }

    const getDashboardSavedFundraisingCampaignsFromUrl = async (url: string) => {
        const results = await fundraisingDataService.fetchSavedFundraisingCampaignsDataForDashboardFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }

    const unSaveFundraisingCampaign = async (slug: string) => {

        const unSavedFundraisingCampaign = await fundraisingDataService.requestToUnSaveFundraisingCampaign(slug);
        if(unSavedFundraisingCampaign){
            useAlertSetter().setSuccessAlert("Successfully Un Saved Fundraiser");
            return unSavedFundraisingCampaign;
        }
        return null;
    };
    /*Save Fundraising Campaign Ends*/

    /*Fundraising Campaign Donation*/
    const getDashboardUserFundraisingCampaignDonations = async () => {
        const dashboardData = await fundraisingDataService.fetchDashboardUserFundraisingCampaignDonationsDataForDashboard();
        return computed(() => dashboardData?.value)
    }

    const getDashboardUserFundraisingCampaignDonationsFromUrl = async (url: string) => {
        const results = await fundraisingDataService.fetchDashboardUserFundraisingCampaignDonationsDataForDashboardFromUrl(url);
        useScrollManager().scrollToTop();
        return computed(() => results)
    }

    /*Fundraising Campaign Donation Ends*/

    return {
        getFundraisingCampaignCategories,
        getFundraisingCampaignTypes,
        getFundraisingCampaignQuota,

        getDashboardFundraisingCampaignsByFilter,
        getDashboardFundraisingCampaignsFromUrl,

        createNewFundraisingCampaign,
        updateFundraisingCampaign,
        getCurrentDashboardFundraisingCampaign,
        completeCurrentDashboardFundraisingCampaign,
        submitCurrentDashboardFundraisingCampaignForApproval,

        getCurrentDashboardFundraisingCampaignImages,

        addImageToCurrentDashboardFundraisingCampaignImages,
        removeImageFromCurrentDashboardFundraisingCampaignImagesAtIndex,

        getDashboardFundraisingCampaignDonationsDataFromUrl,
        getDashboardFundraisingCampaignDonations,

        getDashboardFundraisingCampaignTimelineUpdatesDataFromUrl,
        getDashboardFundraisingCampaignTimelineUpdatesDataByPageNumber,
        getDashboardFundraisingCampaignTimelineUpdates,
        createNewFundraisingCampaignTimelineUpdate,
        updateFundraisingCampaignTimelineUpdate,
        deleteFundraisingCampaignTimelineUpdate,

        getDashboardSavedFundraisingCampaigns,
        getDashboardSavedFundraisingCampaignsFromUrl,
        unSaveFundraisingCampaign,

        getDashboardUserFundraisingCampaignDonations,
        getDashboardUserFundraisingCampaignDonationsFromUrl,
    }
}

